import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Login from "../Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import CDN from "../CDN/CDN";
import Websites from "../Websites/Websites";
import CMS from "../CMS/CMS";
import AppContext from "../../Data/app-context";
import Signup from "../Login/Signup";
import PageNotFound from "../Error/PageNotFound";
import InternalServerError from "../Error/InternalServerError";
import RouteAuthenticated from "./AuthenticatedRoute";
import ForgotPassword from "../Login/ForgotPassword";
import Editor from "../Project/Assets/Editor";
import List from "../Project/Assets/List";
import Add from "../Project/Assets/Add";
import AddProject from "../Project/Add";

const App: React.FC = () => {
  const appContext = useContext(AppContext);
  const [] = useState(false);

  useEffect(() => {
    appContext.initContext();
  }, [appContext.initContext]);

  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route exact path="/logout" render={() => {
              appContext.logout();
                return <></>;
            }
        }/>
        <Route path="/forgotpassword" component={ForgotPassword} />
        <RouteAuthenticated path="/dashboard" component={Dashboard} />
        <RouteAuthenticated path="/cdn" component={CDN} />
        <RouteAuthenticated path="/cms" component={CMS} />
        <RouteAuthenticated path="/websites" component={Websites} />
        <RouteAuthenticated path="/404" component={PageNotFound} />
        <RouteAuthenticated path="/500" component={InternalServerError} />
        <RouteAuthenticated path="/project/add" component={AddProject} />
        <RouteAuthenticated path="/add/:projectId" component={Add} />
        <RouteAuthenticated path="/editor/:id/:imageObject" component={Editor} />
        <RouteAuthenticated path="/project/:id" component={List} />
        <RouteAuthenticated path="/" component={Dashboard} />
      </Switch>
    </Container>
  );
};
export default App;
