import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import AppContext from "../../Data/app-context";
import Footer from "../Layout/Footer";
import TopBar from "../Layout/TopBar";
import Login from "../Login/Login";
import NavBar from "../NavBar";
import valiadateToken from "./verifyToken";

interface RouteAuthenticatedProps {
  path: string;
  component: React.ComponentType<{}>; // a class or function component which requires no props
}

const RouteAuthenticated: React.FC<RouteAuthenticatedProps> = ({
  component: Component,
  path,
}: RouteProps) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    async function authenticate() {
      const isAuthenticated = await valiadateToken();
      appContext.initContext();
      if (!isAuthenticated) {
        history.push("/login");
      }
    }
    authenticate();
  }, []);

  return (
    <React.Fragment>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="stretch"
        // justify="center"
      >
        <Grid item xs={2}>
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
        </Grid>
        <Grid item xs={12} style={{marginBottom:20}}>
          <Route component={Component} path={path} exact />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default RouteAuthenticated;
