import React, { useReducer, useEffect, useState, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import "firebase/auth";
import "firebase/firestore";
import { useHistory } from "react-router-dom";
import AppContext from "../../Data/app-context";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import GoogleIcon from "../Icons/GoogleIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 400,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    header: {
      textAlign: "center",
      background: "#212121",
      color: "#fff",
    },
    card: {
      marginTop: theme.spacing(10),
    },
    grid: {
      marginTop: theme.spacing(10),
    },
  })
);

type State = {
  username: string;
  password: string;
  isButtonDisabled: boolean;
  helperText: string;
  isError: boolean;
};

const initialState: State = {
  username: "",
  password: "",
  isButtonDisabled: true,
  helperText: "",
  isError: false,
};

// interface LoginProps {
//   setToken: (token: any) => void;
// }

const Signup: React.FC = () => {
  const classes = useStyles();
  const [username, setUserName] = useState("");
  const [clientId, setClientId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const appContext = useContext(AppContext);
  const history = useHistory();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    appContext.signup(username, clientId, password, confirmPassword);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Box mb={3} className={classes.card} width="100%">
            <Typography color="textPrimary" variant="h4" align="center">
              Sign up
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Sign up on the apple tree platform
            </Typography>
          </Box>

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={12} alignItems="center">
              <Button
                fullWidth
                startIcon={<GoogleIcon />}
                onClick={handleSubmit}
                size="large"
                variant="contained"
                disabled
              >
                Login with Google
              </Button>
            </Grid>
          </Grid>

          <Box mt={3} mb={1} width="100%">
            <Typography align="center" color="textSecondary" variant="body1">
              or create account with email address
            </Typography>
          </Box>

          {appContext.message && (
            <Snackbar
              open={true}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity={appContext.message.type}>
                {appContext.message.text}
              </Alert>
            </Snackbar>
          )}
          <TextField
            fullWidth
            id="username"
            type="email"
            onChange={(e) => setUserName(e.target.value)}
            label="Username"
            placeholder="Username"
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            id="clientId"
            type="text"
            onChange={(e) => setClientId(e.target.value)}
            label="Client Id"
            placeholder="Client Id"
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            id="password"
            type="password"
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            id="password"
            type="password"
            label="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
            margin="normal"
            variant="outlined"
          />
          
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.loginBtn}
            type="submit"
          >
            Signup
          </Button>
        </form>
      </Container>
    </Box>
  );
};

export default Signup;
