import React from "react";

export interface message {
  text: string;
  type: "error" | "success" | "info" | "warning";
}

export interface projectInterface {
  id?: string;
  name: string;
  intro: string;
  heroImage?: string;
}
export interface cmsInterface {
  project: string;
  environment: string;
  url: string;
}

export interface Context {
  authenticated?: boolean;
  token: string | null;
  message: message | null;
  user: any;
  projects: projectInterface[];
  cms: cmsInterface[];
  websites: cmsInterface[];
  login: (username: string, password: string) => void;
  forgotPassword: (username: string) => void;
  signup: (username: string, clientId:string,  password: string, confirmPassword: string) => void;
  initContext: () => void;
  logout: () => void;
  updateProject: (project:projectInterface) => void;
}

const AppContext = React.createContext<Context>({
  authenticated: false,
  token: '',
  projects: [],
  cms: [],
  websites: [],
  message: {} as message,
  user: {},
  login: () => {},
  forgotPassword: () => {},
  signup: () => {},
  initContext: () => {},
  logout: () => {},
  updateProject: () => {}
});

export default AppContext;
