import {
  IconButton,
  makeStyles,
  Hidden,
} from "@material-ui/core";
import { AppBar, Toolbar, Divider } from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12,
  },
}));

interface topBar {
  onMobileNavOpen: any;
}

const TopBar = ({ onMobileNavOpen }: topBar) => {
  const classes = useStyles();
  
  return (
    <header>
      <AppBar className={clsx(classes.root)} elevation={0}>
        <Toolbar>
          <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <img
              src="https://www.dkefe.com/images/dk-logo.png"
              height="40px"
            />
          </Link>
          <section className={classes.rightToolbar}>
          </section>
          <Hidden xsDown>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Divider variant="fullWidth" />
      </AppBar>
      
    </header>
  );
};
export default TopBar;
