import { Button, Collapse, Grid, IconButton, makeStyles, Snackbar } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import BackButton from "../../Layout/BackButton";
import AWS from 'aws-sdk';
import Alert from "@material-ui/lab/Alert";
import {message} from "../../../Data/app-context";
import CloseIcon from "@material-ui/icons/Close";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 64,
    flexGrow: 1,
    height: "100vh",
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
  grid: {
    paddingLeft: 0,
    paddingRight: 0,
    wordWrap: "break-word",
    height: "100vh",
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  formItem: {
    margin: 0,
  },
  section: {
    border: "solid 1px #ddd",
    borderRadius: 3,
    margin: 10,
    padding: 5,
  },
}));

interface paramInterface {
  projectId: string;
}

const Add = () => {
  const { projectId } = useParams<paramInterface>();
  const [files, setFiles] = useState<File[] | []>();
  const [message, setMessage] = useState<message | null>(null);
  const [open, setOpen] = useState(true);

  AWS.config.update({
    region: "eu-west-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET
  });

  const handleChange = (loadedFiles:any) => {
      setFiles(loadedFiles)
  }

  const uploadImages = async () => {

    try {
      if (files) {
        for (let file of files) {
          await new AWS.S3.ManagedUpload({
            params: {
              Bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME || '',
              Key: `${decodeURIComponent(projectId)}/${file.name}`,
              ContentType: file.type,
              ACL: "public-read",
              Body: file
            }
          }).promise();
        }
        setMessage({
          type: 'success',
          text: "Files uploaded successfully!"
        });
        setFiles([]);
        setOpen(true);
      }
    } catch (error) {
      console.log(process.env);
      
      console.log(error);
      setMessage({
        type: 'error',
        text: "Oops! Please try again later or contact your system admin!"
      });
      setOpen(true);
    }
    
  }


  return (
    <div>
      {message && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Collapse in={open}>
              <Alert
                severity={message.type}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {message.text}
              </Alert>
            </Collapse>
          </Snackbar>
      )}
      <BackButton />
      <Grid
        container
        alignContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <DropzoneArea
            filesLimit={50}
            onChange={handleChange}
            initialFiles={files}
            clearOnUnmount
            showAlerts={false}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          direction="row"
          container
          alignItems="center"
          justify="flex-end"
        >
          {files && files.length > 0 &&
            <Button variant="contained" color="primary" onClick={uploadImages}>
              Upload
          </Button>
          }
        </Grid>
      </Grid>
    </div>
  );
};
export default Add;
