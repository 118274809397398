import { Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 60,
  },
  media: {
    height: 140,
  },
  cardTitle: {
    wordWrap: "break-word",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    marginTop: 75,
  },
}));

export default function BackButton() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid item xs={1} className={classes.root}>
      <Tooltip title="Go back" aria-label="back">
        <Fab
          //className={classes.fab}
          //variant="outlined"
          color="primary"
          size="small"
          //startIcon={<KeyboardBackspaceIcon />}
          onClick={() => history.goBack()}
        >
          <KeyboardBackspaceIcon />
        </Fab>
      </Tooltip>  
    </Grid>
  );
}
