import React, { useState, useCallback } from "react";
import AppContext, { cmsInterface, message, projectInterface } from "./app-context";
import firebase from "../firebase";
import { useHistory } from "react-router-dom";
import AWS from "aws-sdk";

const AppContextProvider: React.FC = (props) => {
  const [token, setToken] = useState<string | null>(null);
  const [message, setMessage] = useState<message | null>(null);
  const [user, setuser] = useState<any>(null);
  const [projects, setProjects] = useState<projectInterface[]>([]);
  const [cms, setCms] = useState<cmsInterface[]>([]);
  const [websites, setWebsites] = useState<cmsInterface[]>([]);
  const history = useHistory();

  const login = async (username: string, password: string) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(async (res) => {
        const idToken = await res.user?.getIdToken();
        if (idToken) {
          setToken(idToken?.toString());
          setuser(res);
          localStorage.setItem("token", idToken.toString());
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.message });
      });
  };

  const forgotPassword = async (username: string) => {
    firebase
      .auth()
      .sendPasswordResetEmail(username)
      .then(async (res) => {
        setMessage({
          type: "success",
          text: "Password receover email sent! Please check your mailbox.",
        });
        history.push("/login");
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.message });
      });
  };

  const signup = async (
    username: string,
    clientId: string,
    password: string,
    confirmPassword: string
  ) => {
    
    if (!clientId || clientId !== new Date().toISOString().slice(0,10).replace(/-/g,"")) {
      setMessage({
        type: "error",
        text: "Invalid client ID. Please try with correct details.",
      });
      return;
    }

    if (password.trim() !== confirmPassword.trim()) {
      setMessage({
        type: "error",
        text: "Password and Confirm Password don't match",
      });
      return;
    }

    //TODO: Validate token using APIs

    firebase
      .auth()
      .createUserWithEmailAndPassword(username, password)
      .then(async (res) => {
        setMessage({ type: "success", text: "User added successfully!" });
        history.push('/login');
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.message });
      });
  };

  const initContext = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    }
    AWS.config.update({
      region: "eu-west-1",
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET
    });

    //Set Projects for CDN
    let _projects = await new AWS.S3().getObject({
      Bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME || '',
      Key: `projects.json`,
    }).promise();
    setProjects(_projects.Body ? JSON.parse(_projects.Body?.toString("utf-8")) : []);
    
    //Set CMS
    let _cms = await new AWS.S3().getObject({
      Bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME || '',
      Key: `cms.json`,
    }).promise();
    setCms(_cms.Body ? JSON.parse(_cms.Body?.toString("utf-8")) : []);

    //Set CMS
    let _websites = await new AWS.S3().getObject({
      Bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME || '',
      Key: `websites.json`,
    }).promise();
    setWebsites(_websites.Body ? JSON.parse(_websites.Body?.toString("utf-8")) : []);

  }, []);

  const updateProject = async (project: projectInterface) => {
    //Check if name exists...then error....
    if (projects.filter(e => e.name === project.name).length > 0) {
      console.log('already there');
      return ;
    }
    //Append project to current list of project
    project.id = project.name;
    projects.push(project)
    //upload projects JSON file to S3
    await new AWS.S3().putObject({
      Bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME || '',
      Key: `projects.json`,
      Body: JSON.stringify(projects),
      ContentType: 'application/JSON',
    }).promise();
    //Refresh Context 
    setProjects(projects);
    return '';
  }

  const logout = async () => {
    localStorage.removeItem("token");
    setToken(null);
    history.push('/login');
  };

  return (
    <AppContext.Provider
      value={{
        projects,
        cms,
        websites,
        login,
        forgotPassword,
        signup,
        initContext,
        logout,
        updateProject,
        token,
        message,
        user,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
