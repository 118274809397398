import {
    Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 60,
  },
  padding: {
    padding: 0,
  },
  mainHeader: {
    padding: 10,
    alignItems: "center",
  },
  mainContent: {
    padding: 10,
  },
  secondaryContainer: {
    padding: "20px 25px",
  },
}));

export default function AddProject() {
  const classes = useStyles();

  return (
    <Dialog
      //className={classes.root}
      fullWidth
      maxWidth="sm"
      open={true}
      //onClose={() => onClose("wireModal")}
    >
      <DialogContent className={classes.padding}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.mainHeader}>
              <Grid item xs={12}>
                <Typography variant="h5">Add Project</Typography>
                <Typography variant="subtitle2" align="left">
                  Enter details for new project
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.mainContent}
              spacing={1}
            >
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label="Name"
                  id="name"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  margin="dense"
                  multiline
                  rows="5"
                  variant="outlined"
                  label="Additional Info"
                  id="additional-info"
                />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                    dropzoneText="Add a hero image for this project"
                    filesLimit={1}
                    //onChange={handleChange}
                    //initialFiles={files}
                    clearOnUnmount
                    showAlerts={false}
                />
              </Grid>
              <Grid item xs={12}>
              <Button variant="contained" color="primary">
                    Cancel
                </Button>
                <Button variant="contained" color="primary" style={{float:"right"}}>
                    Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
