import React, { useState, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import MuiButton from "@material-ui/core/Button";
import { spacing } from "@material-ui/system";
import "firebase/auth";
import "firebase/firestore";
import AppContext from "../../Data/app-context";
import {
  Box,
  Container,
  Typography,
  Grid,
  styled,
  Link,
  IconButton,
  Collapse,
} from "@material-ui/core";
import GoogleIcon from "../Icons/GoogleIcon";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 400,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: 0,
      flexGrow: 1,
      float: "right",
      marginRight: 0,
    },
    header: {
      textAlign: "center",
      background: "#212121",
      color: "#fff",
    },
    card: {
      marginTop: theme.spacing(10),
    },
  })
);

type State = {
  username: string;
  password: string;
  isButtonDisabled: boolean;
  helperText: string;
  isError: boolean;
};

const Login: React.FC = () => {
  const classes = useStyles();
  let history = useHistory();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const appContext = useContext(AppContext);
  const [open, setOpen] = useState(true);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    appContext.login(username, password);
  };

  const signup = async () => {
    history.push("/signup");
  };

  const forgotPassword = async () => {
    history.push("/forgotPassword");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Box mb={3} className={classes.card} width="100%">
            <Typography color="textPrimary" variant="h4" align="center">
              Sign in
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
              align="center"
            >
              Sign in on the apple tree platform
            </Typography>
          </Box>

          <Grid container spacing={3} alignItems="center">
            <Grid container item xs={12} md={12} alignItems="center">
              <Button
                fullWidth
                startIcon={<GoogleIcon />}
                onClick={handleSubmit}
                size="large"
                variant="contained"
                disabled
              >
                Login with Google
              </Button>
            </Grid>
          </Grid>

          <Box mt={3} mb={1} width="100%">
            <Typography align="center" color="textSecondary" variant="body1">
              or login with email address
            </Typography>
          </Box>

          {appContext.message && (
            <Snackbar
              open={true}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Collapse in={open}>
                <Alert
                  severity={appContext.message.type}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {appContext.message.text}
                </Alert>
              </Collapse>
            </Snackbar>
          )}
          <TextField
            fullWidth
            id="username"
            type="email"
            onChange={(e) => setUserName(e.target.value)}
            label="Username"
            placeholder="Username"
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            id="password"
            type="password"
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            margin="normal"
            variant="outlined"
          />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={9}>
              <Link component="button" variant="body2" onClick={forgotPassword}>
                Forgot Password?
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Button
                mr={2}
                variant="contained"
                size="medium"
                color="primary"
                className={classes.loginBtn}
                type="submit"
              >
                Login
              </Button>
            </Grid>
            <Grid item xs={9}>
              <Link component="button" variant="body2" onClick={signup}>
                Register
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default Login;
