import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles,
  Grid,
  Fab,
  Tooltip,
} from "@material-ui/core";

import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import Loading from "../../Layout/Loading";
import BackButton from "../../Layout/BackButton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AWS from "aws-sdk";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 75,
  },
  media: {
    height: 140,
  },
  cardTitle: {
    wordWrap: "break-word",
  },
  fab: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    marginTop: 70,
  },
}));

interface paramInterface {
  id: string;
}

interface objectInterface {
  key: string;
  prettyKey: string;
  url: string;
  type: string;
  size: string;
}

export default function List() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<paramInterface>();
  let [objects, setObjects] = useState<objectInterface[]>([]);
  let [fetched, setFetched] = useState(false);
  const handleAdd = useCallback(() => history.push(`/add/${id}`), [history]);

  const deleteImageHandler = async (key:string) => {
    if(process.env.REACT_APP_ASSETS_BUCKET_NAME && process.env.REACT_APP_ASSETS_BUCKET_NAME !== '')
    {
      await new AWS.S3().deleteObject({
        Bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME,
        Key: `${key}`,
      }).promise();
      fetchData();
    }

  };

  const fetchData = useCallback(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_ENDPOINT}/getListOfObjects/${encodeURIComponent(id)}`,
    })
      .then((response) => {
        setObjects(response.data.images);
        setFetched(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const buttons = (
    <Grid container>
      <BackButton />
      <Grid item xs={1} className={classes.root}>
        <Tooltip title="Add assets" aria-label="add">
          <Fab
            className={classes.fab}
            color="primary"
            size="small"
            onClick={handleAdd}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>
    </Grid>
  );

  if (!fetched) {
    return (
      <React.Fragment>
        {buttons}
        <Loading />
      </React.Fragment>
    );
  }

  if (objects.length === 0) {
    return (
      <React.Fragment>
        {buttons}
        <Typography variant="h4" style={{ textAlign: "center" }}>
          You have not added any asset to this project.
        </Typography>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {buttons}
      <Grid container spacing={10} justify="flex-start" alignItems="stretch">
        {objects.map((object) => (
          <Grid item xs={12} md={3} key={objects.indexOf(object)}>
            <Card>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={object.url}
                  title="Contemplative"
                />
                <CardContent>
                  <Typography
                    variant="caption"
                    component="h5"
                    className={classes.cardTitle}
                  >
                    {object.prettyKey}
                    <b style={{ display: "block" }}>({object.size})</b>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Grid container>
                  <Grid item xs={10} md={10}>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      component={Link}
                      to={`/editor/${id}/${btoa(JSON.stringify(object))}`}
                      endIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Button onClick={(e) => deleteImageHandler(object.key)} color="primary" >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
