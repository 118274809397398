import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles,
  Grid,
  Fab,
  Tooltip,
  Dialog,
  DialogContent,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { useContext } from "react";
import AppContext from "../../Data/app-context";
import { DropzoneArea } from "material-ui-dropzone";
import AWS from "aws-sdk";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 75,
  },
  card: {
    margin: 'auto',
    height: 350
  },
  media: {
    height: 100,
    width: '90%',
    objectFit: 'cover',
    margin: 40
    
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    marginTop: 70,
  },
  padding: {
    padding: 0,
  },
  mainHeader: {
    padding: 10,
    alignItems: "center",
  },
  mainContent: {
    padding: 10,
  },
  secondaryContainer: {
    padding: "20px 25px",
  },
}));

interface projectInterface {
  id: string;
  name: string;
  intro: string;
  heroImage: string;
}

export default function CDN() {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [intro, setIntro] = useState("");
  const [heroImage, setHeroImage] = useState<File>();
  const projects = appContext.projects;
  
  const showModalHandler = () => {
    setModal(true);
  };

  const handleChange = (loadedFiles: any) => {
    setHeroImage(loadedFiles[0]);
  };

  const saveProjectHandler = async () => {
    AWS.config.update({
      region: "eu-west-1",
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET,
    });
    if (heroImage) {
        await new AWS.S3.ManagedUpload({
          params: {
            Bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME || "",
            Key: `${name}/${heroImage.name}`,
            ContentType: heroImage.type,
            ACL: "public-read",
            Body: heroImage,
          },
        }).promise();
    }

    const res = await appContext.updateProject({
      name,
      intro,
      heroImage: heroImage ? `${process.env.REACT_APP_S3_BUCKET_URL}/${name}/${heroImage.name}` : ''
    });
    console.log(res);
    setModal(false);
  };

  return (
    <React.Fragment>
      <Grid item xs={1}>
        <Tooltip title="Add Project" aria-label="add">
          <Fab
            className={classes.fab}
            color="primary"
            size="large"
            onClick={showModalHandler}
            //href="/project/add"
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>
      <Grid
        container
        spacing={2}
        justify="flex-start"
        className={classes.root}
        alignItems="stretch"
      >
        {projects.map((elem) => (
          <Grid item xs={12} md={3} key={projects.indexOf(elem)}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={elem.heroImage}
                  title="Contemplative"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {elem.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {elem.intro}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ width: "100%" }}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      component={Link}
                      to={`/project/${elem.name}`}
                    >
                      Manage Images
                    </Button>
                  </Grid>
                  {/* <Grid item xs={2}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={showModalHandler}
                      style={{ textAlign: "left" }}
                    >
                      <EditIcon />
                    </Button>
                  </Grid> */}
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Add Modal */}
      <Dialog fullWidth maxWidth="sm" open={modal}>
        <DialogContent className={classes.padding}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="row" className={classes.mainHeader}>
                <Grid item xs={12}>
                  <Typography variant="h5">Add Project</Typography>
                  <Typography variant="subtitle2" align="left">
                    Enter details for new project
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.mainContent}
                spacing={1}
              >
                <Grid item xs={7}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Name"
                    id="name"
                    onChange={(e: any) => setName(e.target.value)}
                    required={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    multiline
                    rows="5"
                    variant="outlined"
                    label="Additional Info"
                    id="additional-info"
                    onChange={(e: any) => setIntro(e.target.value)}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropzoneArea
                    dropzoneText="Add a hero image for this project"
                    filesLimit={1}
                    onChange={handleChange}
                    //initialFiles={files}
                    clearOnUnmount
                    showAlerts={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right" }}
                    onClick={saveProjectHandler}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
