// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC3nG74J4H2NYm8TSz9jzRqlEi-Y1AFNkk",
    authDomain: "dkhub-services.firebaseapp.com",
    databaseURL: "https://dkhub-services.firebaseio.com",
    projectId: "dkhub-services",
    storageBucket: "dkhub-services.appspot.com",
    messagingSenderId: "486088782923",
    appId: "1:486088782923:web:678014a507dc903dbbc9ce"
};

export default firebaseConfig;
