import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
} from 'react-feather';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PermMediaIcon from '@material-ui/icons/PermMedia';
import LanguageIcon from '@material-ui/icons/Language';
import DescriptionIcon from '@material-ui/icons/Description';

import NavItem from './NavItem';
import AppContext from '../../Data/app-context';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'DK Employee',
  name: 'Joe Blogs'
};

const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/cdn',
    icon: PermMediaIcon,
    title: 'CDN'
  },
  {
    href: '/cms',
    icon: DescriptionIcon,
    title: 'CMS'
  },
  {
    href: '/websites',
    icon: LanguageIcon,
    title: 'Websites'
  },
  {
    href: '/logout',
    icon: ExitToAppIcon,
    title: 'Logout',
    onClick: 'handleLogout'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 200,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    //width: 64,
    //height: 64
  }
}));

interface navBar {
  onMobileClose: any;
  openMobile: any;
}

const NavBar = ({ onMobileClose, openMobile }:navBar) => {
  const classes = useStyles();
  const location = useLocation();
  const appContext = useContext(AppContext);

  // const handleLogout = async () => {
  //   appContext.logout();
  // };


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography
          color="textPrimary"
        >
          {appContext.user?.user.email}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              Icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden xsDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      {/* <Hidden xsDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden> */}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
