import { makeStyles, Grid, Button } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import AppContext from "../../Data/app-context";
import MUIDataTable from "mui-datatables";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 75,
  },
  media: {
    height: 140,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    marginTop: 70,
  },
  padding: {
    padding: 0,
  },
  mainHeader: {
    padding: 10,
    alignItems: "center",
  },
  mainContent: {
    padding: 10,
  },
  secondaryContainer: {
    padding: "20px 25px",
  },
}));

interface projectInterface {
  id: string;
  name: string;
  intro: string;
  heroImage: string;
}

export default function CMS() {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const columns = [
    "Project",
    "Environment",
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Button
            onClick={() => window.open(value)}
            color="default"
            variant="text"
          >
            Visit Site <OpenInNewIcon />
          </Button>
        ),
      },
    },
  ];

  const data = appContext.cms;

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        justify="flex-start"
        className={classes.root}
        alignItems="stretch"
      >
        <Grid item xs={12} sm={12}>
          <MUIDataTable
            title={"CMS LIST"}
            data={data.map((item) => {
              return [item.project, item.environment, item.url];
            })}
            columns={columns}
            options={{
              selectableRows: "none",
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
