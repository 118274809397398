import {
  Card,
  CardContent,
  CardActions,
  makeStyles,
  Grid,
  Avatar,
  Button,
} from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import AppContext from "../../Data/app-context";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 75,
  },
  media: {
    height: 140,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    marginTop: 70,
  },
  padding: {
    padding: 0,
  },
  mainHeader: {
    padding: 10,
    alignItems: "center",
  },
  mainContent: {
    padding: 10,
  },
  secondaryContainer: {
    padding: "20px 25px",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const projects = appContext.projects;
  const cms = appContext.cms;
  const websites = appContext.websites;

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.root}
        alignItems="center"
        justify="center"
        style={{ gap: 15, textAlign:"center" }}
      >
        <Grid item alignItems="center" xs={3}>
          <Card
            style={{
              backgroundColor: "rgb(208, 242, 255)",
              textAlign:"center"
            }}
          >
            <CardContent>
              <Avatar style={{ margin:"auto", color: "rgb(208, 242, 255)",textAlign:"center"  }}>
                <DescriptionIcon  />
              </Avatar>
              <h1>{cms.length} <br />Content Management Systems</h1>
              <h4>(Including production and non-production)</h4>
            </CardContent>
            <CardActions style={{justifyContent: 'center'}}>
              <Button href="/cms" variant="contained">Find more</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item alignItems="center" xs={3}>
          <Card style={{ backgroundColor: "rgb(255, 247, 205)" }}>
            <CardContent>
              <Avatar style={{ margin:"auto", color: "rgb(255, 247, 205)",textAlign:"center"  }}>
                <LanguageIcon />
              </Avatar>
              <h1>{websites.length} <br />Websites</h1>
              <h4>(Including production and non-production)</h4>
            </CardContent>
            <CardActions style={{justifyContent: 'center'}}>
              <Button href="websites" variant="contained">Find more</Button>
            </CardActions>
          </Card>
        </Grid> 

        <Grid item alignItems="center" xs={3}>
          <Card style={{ backgroundColor: "rgb(255, 231, 217)" }}>
            <CardContent>
              <Avatar style={{ margin:"auto", color: "rgb(255, 231, 217)" ,textAlign:"center"  }}>
                <PermMediaIcon />
              </Avatar>
              <h1>{projects.length} <br />CDN projects</h1>
              <h4>(Including production and non-production)</h4>
            </CardContent>
            <CardActions style={{justifyContent: 'center'}}>
              <Button href="cdn" variant="contained">Find more</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
