const getToken = () => {
    try {
        const tokenString = localStorage.getItem('token');
        return tokenString || null;
        //return userToken?.token;
    } catch (error) {
        return null;
    }
};

const valiadateToken = async () => {

    const token = getToken();

    if (!token) {
        localStorage.removeItem('token')
        //window.location.reload();
        return false;
    }

    //TODO: Add this endpoint for firebase project
    // const response = await fetch(`https://europe-west1-appletree-auth.cloudfunctions.net/users/validateToken`, {
    //     method: 'POST',
    //     headers: {
    //         'x-api-key': 'f5659b818c109796c75588c8b514355d3beaf6f6',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ token }),
    // });

    // if (response.status !== 200) {
    //     localStorage.removeItem('token');
    //     //window.location.reload();
    //     return false;
    // }

    return true;

}

export default valiadateToken;