import { Typography, CircularProgress } from "@material-ui/core";
import React from "react";

export default function Loading() {
  return (
    <React.Fragment>
      <CircularProgress style={{ marginTop: 125, textAlign:"center", alignContent:"center" }} />
      <Typography variant="subtitle1" style={{ textAlign: "center" }} >Loading...</Typography>
    </React.Fragment>
  );
}
