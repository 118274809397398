import { colors } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ddd',
      light: '#fff',
      dark: '#fff',
    },
    secondary: {
      main: '#b5ecfb',
      light: '#fff',
      dark: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: colors.common.white,
      paper: colors.common.white
    },
  },
  
});
export default theme;