import {
  makeStyles,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select,
  styled,
  Divider,
  TextField,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Collapse,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import { spacing } from "@material-ui/system";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import ColorPicker from 'material-ui-color-picker'
import SaveIcon from '@material-ui/icons/Save';
import BackButton from "../../Layout/BackButton";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import {message} from "../../../Data/app-context";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginTop: 64,
    flexGrow: 1,
    height: "100vh",
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
  grid: {
    paddingLeft: 0,
    paddingRight: 0,
    wordWrap: "break-word",
    height: "100vh",
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  formItem: {
    margin: 0,
  },
  section: {
    border: "solid 1px #ddd",
    borderRadius: 3,
    margin: 10,
    padding: 5,
  },
}));

interface imageObjectInterface {
  key: string;
  prettyKey: string;
  url: string;
}
interface paramInterface {
  imageObject: string;
  id: string;
}

interface requestObject {
  bucket: string;
  key: string;
  edits?: {
    resize?: {
      width?: number;
      height?: number;
      fit?: string;
      background?: {
        r: number;
        g: number;
        b: number;
        alpha: number | null;
      };
    };
    flatten?: boolean;
    grayscale?: boolean;
    flip?: boolean;
    flop?: boolean;
    negate?: boolean;
    normalise?: boolean;
    rotate?: any;
  };
}

const Editor = () => {
  const classes = useStyles();
  const { imageObject, id } = useParams<paramInterface>();
  const _imageObject: imageObjectInterface = JSON.parse(atob(imageObject));
  const [width, setWidth] = useState(0);
  const [height, setheight] = useState(0);
  const [mode, setMode] = useState("");
  const [fillColor, setFillColor] = useState("");
  const [grayScale, setGrayScale] = useState(false);
  const [negative, setNegative] = useState(false);
  const [flip, setFlip] = useState(false);
  const [flop, setFlop] = useState(false);
  const [flatten, setFlatten] = useState(false);
  const [normalize, setNormalize] = useState(false);
  const [body, setBody] = useState({});
  const [previewImage, setPreviewImage] = useState("https://htlaccess.com/images/loading.gif");
  const [save, setSave] = useState(false);
  const Button = styled(MuiButton)(spacing);
  const [message, setMessage] = useState<message | null>(null);

  

  const generateImage = () => {
    const _requestObject = {} as requestObject;
    _requestObject.edits = {};
    _requestObject.edits.rotate = null;
    _requestObject.edits.resize = {};
    _requestObject.bucket = process.env.S3_BUCKET_NAME || 'dk-cdn-prod';
    _requestObject.key = `${_imageObject.key}`;
    
    if (mode) {
      _requestObject.edits.resize.fit = mode;
    }

    if (width > 0) {
      _requestObject.edits.resize.width = Number(width);
    }

    if (height > 0) {
      _requestObject.edits.resize.height = Number(height);
    }

    if (fillColor) {
      _requestObject.edits.resize.background = hexToRgbA(fillColor, 1);
    }

    if (flatten) _requestObject.edits.flatten = true;
    if (grayScale) _requestObject.edits.grayscale = true;
    if (flip) _requestObject.edits.flip = true;
    if (flop) _requestObject.edits.flop = true;
    if (negative) _requestObject.edits.negate = true;
    if (normalize) _requestObject.edits.normalise = true;


    setPreviewImage(
      `${process.env.REACT_APP_CLOUDFRONT_URL}/${btoa(
        JSON.stringify(_requestObject)
      )}`
    );
    setBody(_requestObject);
  };

  const saveImageHandler = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_ENDPOINT}/saveImage`,
      data: {
        edits: JSON.stringify(body),
        url: previewImage
      }
    })
      .then((response) => {
        setSave(true);
        setMessage({
          type: "success",
          text: "Image save successfully.",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const openInBrowser = () => {
    window.open(previewImage, "_blank");
  };

  const hexToRgbA = (hex: string, _alpha: number) => {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return {
        r: (c >> 16) & 255,
        g: (c >> 8) & 255,
        b: c & 255,
        alpha: Number(_alpha),
      };
    }
    throw new Error("Bad Hex");
  };

  return (
    <div >
      <BackButton />
      {message && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Collapse in={save}>
              <Alert
                severity={message.type}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSave(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {message.text}
              </Alert>
            </Collapse>
          </Snackbar>
        )}
      <Grid
        container
        alignItems="flex-start"
        spacing={0}
        className={classes.grid}
      >
        {/* Image source */}
        <Grid item xs={12} md={3}>
          <Card className={classes.section}>
            <CardActionArea>
              <Typography gutterBottom variant="subtitle1" component="h2">
                Original Image
              </Typography>
              <Divider />
              <br />
              <CardMedia
                image={_imageObject.url}
                title="Preview Image"
                style={{ height: 0, paddingTop: "56.25%" }}
              />
            </CardActionArea>
          </Card>
        </Grid>

        {/* Image editor */}
        <Grid item xs={12} md={5} className={classes.grid}>
          <Card className={classes.section}>
            <Typography gutterBottom variant="subtitle1" component="h2">
              Editor
            </Typography>
            <Divider />
            <br />
            <FormControl className={classes.formControl}>
              <Grid container alignItems="stretch" spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    id="standard-basic"
                    label="Width"
                    value={width}
                    onChange={(e: any) => setWidth(e.target.value)}
                    className={classes.formItem}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    type="number"
                    id="standard-basic"
                    label="Height"
                    value={height}
                    onChange={(e: any) => setheight(e.target.value)}
                    className={classes.formItem}
                  />
                </Grid>

                <Grid item xs={10}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    style={{ marginTop: 10 }}
                  >
                    <InputLabel id="resize-mode">Resize Mode</InputLabel>
                    <Select
                      id="resize-mode-basic"
                      labelId="resize-mode"
                      className={classes.formItem}
                      onChange={(e: any) => setMode(e.target.value)}
                      defaultValue={mode}
                    >
                      <MenuItem value={"fill"}>Fill</MenuItem>
                      <MenuItem value={"contain"}>Contain</MenuItem>
                      <MenuItem value={"cover"}>Cover</MenuItem>
                      <MenuItem value={"inside"}>Inside</MenuItem>
                      <MenuItem value={"outside"}>Outside</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4} style={{ marginTop: 10 }}>
                  <ColorPicker
                    name='fillColor'
                    label="Fill Color"
                    //defaultValue="Fill Color"
                    value={fillColor}
                    onChange={(color: string) => setFillColor(color)}
                  />
                </Grid>
                <Grid item xs={8} style={{ marginTop: 10 }}>
                  <Typography variant="subtitle2" style={{padding: 6,
                      fontSize: 14,
                      backgroundColor: "#cdd0c6"}}>
                    Use this to adjust the backfill color of an image that has been resized and scaled to fit the new dimensions, usually when 'Resize Mode' is set to 'Contain'
                  </Typography>
                </Grid>

                <Grid item xs={4} style={{ marginTop: 10 }}>
                  <FormControlLabel
                    control={<Checkbox name="checkedB" color="primary" />}
                    label="Grayscale"
                    onClick={(e: any) => setGrayScale(e.target.checked)}
                  />
                </Grid>

                <Grid item xs={4} style={{ marginTop: 10 }}>
                  <FormControlLabel
                    control={<Checkbox name="checkedB" color="primary" />}
                    label="Negative"
                    onClick={(e: any) => setNegative(e.target.checked)}
                  />
                </Grid>

                <Grid item xs={4} style={{ marginTop: 10 }}>
                  <FormControlLabel
                    control={<Checkbox name="checkedB" color="primary" />}
                    label="Flip"
                    onClick={(e: any) => setFlip(e.target.checked)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControlLabel
                    control={<Checkbox name="checkedB" color="primary" />}
                    label="Flop"
                    onClick={(e: any) => setFlop(e.target.checked)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControlLabel
                    control={<Checkbox name="checkedB" color="primary" />}
                    label="Flatten"
                    onClick={(e: any) => setFlatten(e.target.checked)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControlLabel
                    control={<Checkbox name="checkedB" color="primary" />}
                    label="Normalize"
                    onClick={(e: any) => setNormalize(e.target.checked)}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                alignItems="stretch"
                spacing={4}
                className={classes.formItem}
              ></Grid>
            </FormControl>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={generateImage}
                variant="contained"
              >
                Generate Image
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Image preview */}
        {Object.keys(body).length > 0 &&
          <Grid item xs={12} md={4} className={classes.grid}>
            {previewImage && (
              <Card className={classes.section}>
                <CardActionArea>
                  <Typography gutterBottom variant="subtitle1" component="h2">
                    Preview Image
                  </Typography>
                  <Divider />
                  <br />
                  {/* <CardMedia
                    image={previewImage}
                    title="Preview Image"
                    style={{ height: 0, paddingTop: "100%" }}
                  /> */}
                  <img src={previewImage} style={{ maxWidth: "350px" }} />
                  {/* <CardContent> */}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                  >
                    <pre
                      style={{
                        padding: 6,
                        fontSize: 14,
                        backgroundColor: "#cdd0c6",
                      }}
                    >
                      {JSON.stringify(body, null, 2)}
                    </pre>
                  </Typography>
                  {/* </CardContent> */}
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={openInBrowser}
                    variant="contained"
                    endIcon={<OpenInNewIcon />}
                  >
                    View on browser
                  </Button>

                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={saveImageHandler}
                    endIcon={<SaveIcon />}
                  >
                    Save
                  </Button>

                </CardActions>
              </Card>
            )}
          </Grid>}
      </Grid>
    </div>
  );
};
export default Editor;
